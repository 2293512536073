import React, { FunctionComponent, useState, useEffect, useRef } from "react";
import Section from "../shared/section/sectionWrapper";
import Text from "../shared/text";
import ReactMarkdown from "react-markdown";
import ApplyForm from "./form";
import AniLink from 'gatsby-plugin-transition-link/AniLink'

interface jobDetails {
    data: {
        jobTitle: string,
        jobBreif: string,
        location: string,
        stateAndCountry: string,
        responsibilities: string,
        requirementsAndSkill: string,
        position: string
    }
}

const Apply: FunctionComponent<jobDetails> = ({ data }) => {


    return (
        <div>
            <Section className="bg-bytestrone-blue">
                <div className=" mx-10">

                    <div className="flex flex-col mt-5 w-full text-white h-full p-5 md:py-10 py-20 space-y-4">
                        <Text textType="headerL" className="text-center  lg:text-right whitespace-nowrap">{data?.jobTitle}</Text>
                        <div className="grid grid-flow-row gap-y-9 justify-items-start">

                            <div className="text-start  py-2 md:text-justify " id="jobBreif">
                                <Text textType="contentXl">
                                    <ReactMarkdown children={data?.jobBreif}></ReactMarkdown></Text>

                            </div>
                            <div className="py-2" id="location">
                                <Text textType="contentL" className="uppercase  ">
                                    {/* <Text textType="contentL" className="font-semibold ">  location : </Text> {data.location} {data.stateAndCountry} */}
                                    location : {data?.location} {data?.stateAndCountry}
                                </Text>
                            </div>
                            <div className="py-2" >
                                <Text textType="contentXXl" className="font-semibold"> Requirements And Skill</Text>
                                {/* {data.requirementsAndSkill} */}
                                <div className=" py-3 pl-5" id="requirementsAndSkill">
                                    <Text textType="contentL">
                                        <ReactMarkdown children={data?.requirementsAndSkill} skipHtml={false}></ReactMarkdown></Text>
                                </div>
                            </div>

                            <div>
                                <Text textType="contentXXl" className="font-semibold">Responsibilities</Text>
                                {/* {data.responsibilities} */}
                                <div className=" py-3 pl-5" id="responsibilities">
                                    <Text textType="contentL" >
                                        <ReactMarkdown children={data?.responsibilities} ></ReactMarkdown></Text>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Section>
            <Section className="bg-white">
                <div id="precision-team" className="pb-5"></div>
                <div className="flex flex-col w-full h-full p-5  py-10 space-y-5">
                    <Text
                        className="text-center text-bytestrone-blue lg:text-left whitespace-nowrap"
                        textType="headerL"
                    >
                        Apply
                    </Text>
                    <div className="">
                        <div className="flex flex-col w-full h-full p-5  py-10 space-y-5">
                            <Text textType="contentXl">
                                Apply for the <strong>{data?.jobTitle}</strong> position.
                            </Text>
                        </div>
                        {/* <div className="flex flex-row  flex-wrap flex-grow"> */}
                        <ApplyForm applyingFor={`Applying For : ${data?.jobTitle} position`} ></ApplyForm>
                    </div>
                    <div className="flex self-start w-32 h-8 items-center rounded-full bg-bytestrone-green  text-white text-center ">
                        <AniLink className="font-bytestrone   w-full rounded-full" to="/careers/find-open-positions">BACK</AniLink>
                    {/* <button type="button" className=" bg-bytestrone-green self-center text-white  min-w-max max-w-sm   md:text-base   font-medium rounded-full focus:outline-none uppercase" onClick={() => navigate(`/careers/find-open-positions}`)}>BACK</button> */}
                    </div>

                </div>
            </Section>
        </div>
    );
};


export default Apply;


