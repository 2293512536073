import { graphql, useStaticQuery } from "gatsby";
import React, { FunctionComponent, useState, useEffect, useRef, Children } from "react";
import Apply from "../../components/careers/apply";


const ApplyJob: FunctionComponent = (props:any) => {

    const data = props.pageContext.jobDetails;

    return (
        <>
            <Apply data={data}></Apply>
        </>
    )
}


export default ApplyJob;